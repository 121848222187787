<svelte:options immutable={true} />

<script lang="ts">
	import Icon from '@iconify/svelte';
	import BadgeTag from '$lib/components/badge/BadgeTag.svelte';
</script>

<div
	class="flex flex-col gap-12 items-center text-center px-4 pt-20 w-full max-w-screen-xl mx-auto mb-20 relative"
>
	<BadgeTag
		href="/blog/why-are-we-creating-easypagego/"
		icon="mingcute:announcement-line"
		tag="Announcement"
		label="Why are we creating EasyPageGo?"
	/>

	<h1 class="text-center text-4xl font-semibold leading-tight sm:text-5xl md:text-6xl">
		Launch Fast and Focus on your Startup
	</h1>
	<div class="prose sm:prose-lg md:prose-xl md:leading-snug">
		<p class="text-balance">
			Web based no-code editor to easily build <strong> stunning landing pages</strong> and fully
			featured
			<strong>documentation sites</strong> without any coding or design knowledge.
		</p>
		<p class="text-balance">
			Download optimized static sites and <u>host anywhere</u>.
			<strong>No platform lock-in</strong>.
		</p>
	</div>

	<a href="/#pricing" class="btn btn-primary">
		<Icon icon="ph:star" width="24" height="24" />
		<span>Get started</span>
	</a>
	<p class="text-sm">Let's grow together.</p>
	<div
		aria-hidden="true"
		class="absolute top-0 left-0 right-0 h-screen w-[200vw] -translate-x-1/2 fade-b -z-50"
	>
		<svg class="" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<pattern id="dotPattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
					<circle class="fill-neutral/20" cx="5" cy="5" r="1"></circle></pattern
				>
			</defs>
			<rect x="0" y="0" width="100%" height="100%" fill="url(#dotPattern)"></rect>
		</svg>
	</div>
</div>
