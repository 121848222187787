<script lang="ts">
	import { browser } from '$app/environment';
	import { onMount } from 'svelte';
	import { page } from '$app/stores';
	import { addToastComponent } from '$lib/components/Toasts.svelte';
	import ToastThankyou from '$lib/components/toasts/ToastThankyou.svelte';
	import LandingHero from '$lib/components/_/landing/LandingHero.svelte';
	import LandingMinuteVideo from '$lib/components/_/landing/LandingMinuteVideo.svelte';
	import LandingProblem from '$lib/components/_/landing/LandingProblem.svelte';
	import LandingSolution from '$lib/components/_/landing/LandingSolution.svelte';
	import LandingHowItWorks from '$lib/components/_/landing/howItWorks/LandingHowItWorks.svelte';
	import LandingCtaSection from '$lib/components/_/landing/LandingCTASection.svelte';
	import LandingFaq from '$lib/components/_/landing/LandingFAQ.svelte';
	import LandingPricing from '$lib/components/_/landing/LandingPricing.svelte';

	export let data;

	if (browser)
		onMount(() => {
			const thankyou = $page.url.searchParams.get('thankyou');
			if (!thankyou) return;

			addToastComponent('thankyou', ToastThankyou);
		});
</script>

<svelte:head>
	<title>{data.context.title}</title>
	<meta name="description" content={data.context.og.description} />
	<meta property="og:title" content={data.context.og.title} />
	<meta property="og:description" content={data.context.og.description} />
	<meta property="og:url" content={data.context.og.url} />
	<meta property="og:type" content="website" />
	<meta property="og:image" content={data.context.og.image.url} />
	<meta property="og:image:alt" content={data.context.og.image.alt} />
	<meta property="og:image:type" content={data.context.og.image.type} />
	<meta property="og:image:width" content={data.context.og.image.width} />
	<meta property="og:image:height" content={data.context.og.image.height} />
	<meta name="twitter:title" content={data.context.og.title} />
	<meta name="twitter:description" content={data.context.og.description} />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:image" content={data.context.og.image.url} />
	<meta name="twitter:image:alt" content={data.context.og.image.alt} />
</svelte:head>

<main>
	<LandingHero />
	<!-- <LandingMinuteVideo /> -->
	<LandingProblem />
	<LandingSolution />
	<LandingHowItWorks />
	<LandingPricing />
	<LandingFaq />
	<LandingCtaSection />
</main>
