<script lang="ts">
	import { io } from '$lib/actions/io';
	import { cubicInOut } from 'svelte/easing';
	import { tweened } from 'svelte/motion';
	import { onDestroy } from 'svelte';
	import { cn } from '$lib/utils/style';

	export let interval: number;

	const ITEMS_AMOUNT = 3;
	let index = 0;
	const progress = tweened(0, {
		duration: interval,
		easing: cubicInOut
	});

	let timeoutID: number | undefined = undefined;
	function start() {
		progress.set(100);
		timeoutID = setTimeout(() => {
			index = (index + 1) % ITEMS_AMOUNT;
			progress.set(0, { duration: 0 });
			start();
		}, interval);
	}
	function stop() {
		progress.set(0, { duration: 0 });
		timeoutID && clearInterval(timeoutID);
	}
	function next() {
		stop();
		index = (index + 1) % ITEMS_AMOUNT;
		start();
	}
	onDestroy(stop);

	const srcs = ['/images/edit.png', '/images/build.png', '/images/upload.png'];
	const alts = ['Edit via the Editor', 'One click build and download', 'deploy anywere'];
</script>

<div
	use:io={{
		cbIn: start,
		cbOut: stop
	}}
	class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto mb-40"
>
	<span class="uppercase font-mono text-primary font-medium tracking-wide">How does it work?</span>
	<h2 class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center">
		Well, it couldn't get easier...
	</h2>

	<ul class="mt-4 sm:mt-12 md:mt-16 w-full">
		<li class={cn(index === 0 ? 'visible' : 'hidden xl:block', 'w-3/4 mx-auto')}>
			<button
				type="button"
				class="w-full text-left focus:outline-offset-[20px] focus:outline-base-content"
				on:click={next}
			>
				<div class="bg-base-300 w-full aspect-video border-2 border-neutral rounded-box">
					<img src={srcs[0]} alt={alts[0]} class="w-full h-auto bg-cover rounded-box" />
				</div>

				<div class="mt-8">
					<progress class="progress progress-primary h-1" value={$progress} max="100" />

					<h3 class="mt-4 text-xl font-semibold capitalize">1. Create with the Intuitive Editor</h3>
					<p class="mt-4 prose">
						Add your content into easy-to-use templates with our intuitive editor. You’ll achieve a
						professional look for your landing pages and documentation sites without needing design
						skills.
					</p>
				</div>
			</button>
		</li>
		<li class={cn(index === 1 ? 'visible' : 'hidden xl:block', 'w-3/4 mx-auto')}>
			<button
				type="button"
				class="w-full text-left focus:outline-offset-[20px] focus:outline-base-content"
				on:click={next}
			>
				<div class="bg-base-300 w-full aspect-video border-2 border-neutral rounded-box">
					<img src={srcs[1]} alt={alts[1]} class="w-full h-auto bg-cover rounded-box" />
				</div>

				<div class="mt-8">
					<progress class="progress progress-primary h-1" value={$progress} max="100" />

					<h3 class="mt-4 text-xl font-semibold capitalize">
						2. Build and Download with One Click
					</h3>
					<p class="mt-4 prose">
						Schedule your website build with a single click. Download a compressed, optimized
						version of your site, ensuring fast performance and efficiency.
					</p>
				</div>
			</button>
		</li>
		<li class={cn(index === 2 ? 'visible' : 'hidden xl:block', 'w-3/4 mx-auto')}>
			<button
				type="button"
				class="w-full text-left focus:outline-offset-[20px] focus:outline-base-content"
				on:click={next}
			>
				<div class="bg-base-300 w-full aspect-video border-2 border-neutral rounded-box">
					<img src={srcs[2]} alt={alts[2]} class="w-full h-auto bg-cover rounded-box" />
				</div>

				<div class="mt-8">
					<progress class="progress progress-primary h-1" value={$progress} max="100" />

					<h3 class="mt-4 text-xl font-semibold capitalize">3. Deploy Anywhere, No Lock-in</h3>
					<p class="mt-4 prose">
						After downloading, deploy your static website on any hosting platform you choose. Enjoy
						the freedom of no platform lock-in and control over where your site is hosted.
					</p>
				</div>
			</button>
		</li>
	</ul>
</div>
