<script lang="ts">
	import { browser } from '$app/environment';
	import LandingHowItWorksDesktop from './LandingHowItWorksDesktop.svelte';
	import LandingHowItWorksMobile from './LandingHowItWorksMobile.svelte';

	export let interval = 5000;

	const breakpoint = 1024;
	let mobile = browser ? window.innerWidth < breakpoint : true;
</script>

<svelte:window on:resize={() => (mobile = window.innerWidth < breakpoint)} />

{#if mobile}
	<LandingHowItWorksMobile {interval} />
{:else}
	{#await import('./LandingHowItWorksDesktop.svelte')}
		<LandingHowItWorksMobile {interval} />
	{:then}
		<LandingHowItWorksDesktop {interval} />
	{:catch}
		<LandingHowItWorksMobile {interval} />
	{/await}
{/if}
