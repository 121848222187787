import { browser } from "$app/environment"
import type { Action } from 'svelte/action'

export const io: Action<HTMLElement, IntersectionObserverInit & { cbIn?: () => void, cbOut?: () => void }> = (node, init) => {
    if (!browser) return {}

    const { cbIn = () => void 0, cbOut = () => void 0, ...opts } = init
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    cbIn()
                } else {
                    cbOut()
                }
            })
        },
        opts
    )

    observer.observe(node)

    return {
        destroy() {
            observer.disconnect()
        }
    }
}
