<script>
	import Icon from '@iconify/svelte';
</script>

<div
	class="w-screen py-20 md:py-24 lg:py-28 xl:py-32 bg-gradient-to-tr from-primary to-secondary text-primary-content"
>
	<div class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto">
		<span class="uppercase font-mono font-medium tracking-wide"> Ready to solve the problem? </span>
		<h2
			id="how-does-it-works"
			class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center"
		>
			Get it done.
		</h2>
		<a href="/#pricing" class="mt-10 btn btn-lg">
			<Icon icon="ph:star" width="24" height="24" />
			<span>Get started</span>
		</a>
	</div>
</div>
