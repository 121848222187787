<script lang="ts">
	import { io } from '$lib/actions/io';
	import { linear } from 'svelte/easing';
	import { tweened } from 'svelte/motion';
	import { onDestroy } from 'svelte';
	import Icon from '@iconify/svelte';
	import { slide } from 'svelte/transition';

	export let interval: number;

	const ITEMS_AMOUNT = 3;
	let index = 0;
	const progress1 = tweened(0, {
		duration: interval,
		easing: linear
	});
	const progress2 = tweened(0, {
		duration: interval,
		easing: linear
	});
	const progress3 = tweened(0, {
		duration: interval,
		easing: linear
	});
	const progress = [progress1, progress2, progress3];

	let timeoutID: number | undefined = undefined;
	function start() {
		progress[index].set(100);
		timeoutID = setTimeout(() => {
			progress[index].set(0, { duration: interval * 0.1 });
			index = (index + 1) % ITEMS_AMOUNT;
			start();
		}, interval);
	}
	function stop() {
		timeoutID && clearInterval(timeoutID);
	}
	onDestroy(stop);
	function next(selectedIndex: 0 | 1 | 2) {
		stop();
		progress.forEach((p) => p.set(0, { duration: interval * 0.1 }));
		index = selectedIndex;
		start();
	}

	const srcs = ['/images/edit.png', '/images/build.png', '/images/upload.png'];
	const alts = ['Edit via the Editor', 'One click build and download', 'deploy anywere'];
</script>

<div
	use:io={{
		cbIn: start,
		cbOut: stop
	}}
	class="flex flex-col items-center px-4 w-full max-w-screen-xl mx-auto mb-40"
>
	<span class="uppercase font-mono text-primary font-medium tracking-wide">How does it work?</span>
	<h2
		id="how-does-it-works"
		class="mx-auto mt-4 text-3xl font-semibold sm:text-4xl md:text-5xl text-center"
	>
		Well, it couldn't get easier...
	</h2>

	<div class="mt-4 sm:mt-12 md:mt-16 w-full grid grid-cols-2 gap-10 xl:gap-14">
		<ul class="flex flex-col gap-8">
			<li class="w-full">
				<button
					type="button"
					class="w-full text-left flex items-center gap-8 focus:outline-offset-[20px] focus:outline-base-content"
					on:click={() => next(0)}
				>
					<div
						class="radial-progress bg-primary/10 text-primary/80 flex-shrink-0"
						style="--value:{$progress1}; --size: 4rem; --thickness: {$progress1.toFixed(0) == '0'
							? '0'
							: '0.2'}rem;"
						role="progressbar"
					>
						<Icon
							icon="material-symbols:edit-document-outline"
							width="32"
							height="32"
							class="text-primary"
						/>
					</div>

					<div>
						<h3 class="mt-3 text-xl font-semibold capitalize">
							1. Create with the Intuitive Editor
						</h3>
						<p class="mt-4 prose">
							Add your content into easy-to-use templates with our intuitive editor. You’ll achieve
							a professional look for your landing pages and documentation sites without needing
							design skills.
						</p>
					</div>
				</button>
			</li>
			<li class="w-full">
				<button
					type="button"
					class="w-full text-left flex items-center gap-8 focus:outline-offset-[20px] focus:outline-base-content hue-rotate-[5deg]"
					on:click={() => next(1)}
				>
					<div
						class="radial-progress bg-primary/10 text-primary/80 flex-shrink-0"
						style="--value:{$progress2}; --size: 4rem; --thickness: {$progress2.toFixed(0) == '0'
							? '0'
							: '0.2'}rem;"
						role="progressbar"
					>
						<Icon
							icon="material-symbols:deployed-code-update-outline"
							width="32"
							height="32"
							class="text-primary"
						/>
					</div>

					<div>
						<h3 class="mt-3 text-xl font-semibold capitalize">
							2. Build and Download with One Click
						</h3>
						<p class="mt-4 prose">
							Schedule your website build with a single click. Download a compressed, optimized
							version of your site, ensuring fast performance and efficiency.
						</p>
					</div>
				</button>
			</li>
			<li class="w-full">
				<button
					type="button"
					class="w-full text-left flex items-center gap-8 focus:outline-offset-[20px] focus:outline-base-content"
					on:click={() => next(2)}
				>
					<div
						class="radial-progress bg-primary/10 text-primary/80 flex-shrink-0 hue-rotate-[10deg]"
						style="--value:{$progress3}; --size: 4rem; --thickness: {$progress3.toFixed(0) == '0'
							? '0'
							: '0.2'}rem;"
						role="progressbar"
					>
						<Icon
							icon="majesticons:planet-rocket-line"
							width="32"
							height="32"
							class="text-primary"
						/>
					</div>

					<div>
						<h3 class="mt-3 text-xl font-semibold capitalize">3. Deploy Anywhere, No Lock-in</h3>
						<p class="mt-4 prose">
							After downloading, deploy your static website on any hosting platform you choose.
							Enjoy the freedom of no platform lock-in and control over where your site is hosted.
						</p>
					</div>
				</button>
			</li>
		</ul>
		<div class="grid place-content-center">
			{#key index}
				<div
					transition:slide
					class="bg-base-200 aspect-video w-[500px] xl:w-[600px] border-2 border-neutral rounded-box"
				>
					<img src={srcs[index]} alt={alts[index]} class="w-full h-auto bg-cover rounded-box" />
				</div>
			{/key}
		</div>
	</div>
</div>
