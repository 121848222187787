<script lang="ts">
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';

	export let href: string | undefined = undefined;
	export let label: string;
	export let icon: string | undefined = undefined;
	export let tag: string | undefined = undefined;
	let className = '';
	export { className as class };
</script>

{#if href}
	<a
		{href}
		class={cn(
			'w-fit flex items-center justify-center flex-wrap rounded-3xl sm:rounded-full gap-3 pl-1 pr-2.5 py-1 font-medium text-sm bg-primary/80 text-primary-content shadow-none hover:shadow-sm transition-shadow',
			className
		)}
		target="_blank"
	>
		<div class="flex items-center gap-1 rounded-full bg-base-100 text-base-content px-2 py-0.5">
			{#if tag}
				{#if icon}
					<Icon {icon} width="20" height="20" />
				{/if}
				<span class="capitalize font-semibold">{tag}</span>
			{/if}
		</div>
		<div class="flex items-center gap-1 flex-nowrap text-center">
			<span>{@html label}</span>
			<Icon icon="mdi:arrow-right" width="16" height="16" class="hidden sm:block" />
		</div>
	</a>
{:else}
	<div
		class={cn(
			'w-fit flex items-center justify-center flex-wrap rounded-3xl sm:rounded-full gap-3 pl-1 pr-2.5 py-1 font-medium text-sm bg-primary/80 text-primary-content shadow-none hover:shadow-sm transition-shadow',
			className
		)}
	>
		<div class="flex items-center gap-1 rounded-full bg-base-100 text-base-content px-2 py-0.5">
			{#if tag}
				{#if icon}
					<Icon {icon} width="20" height="20" />
				{/if}
				<span class="capitalize font-semibold">{tag}</span>
			{/if}
		</div>
		<div class="flex items-center gap-1 flex-nowrap text-center">
			<span>{@html label}</span>
		</div>
	</div>
{/if}
